import * as React from 'react';
import { twMerge } from 'tailwind-merge';

import heroBannerBackground from '@/public/assets/hero-banner-bg.jpeg';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

type PageTitleProps = {
  topComponent?: React.ReactNode;
  title: string;
  description?: string;
  className?: string;
};

function PageTitle({
  topComponent,
  title,
  description,
  className,
}: PageTitleProps) {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <section
      className={twMerge(
        'pt-12 pb-12 lg:pt-20 lg:pb-20',
        useHighContrast ? 'bg-contrast-yellow text-black' : '',
        className
      )}
      style={{
        backgroundImage: useHighContrast
          ? 'unset'
          : `url(${heroBannerBackground.src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
      }}
    >
      <div className="container flex flex-col items-center">
        {topComponent}

        <h1
          className={twMerge(
            'text-4xl font-medium text-center',
            useHighContrast ? 'text-inherit' : 'text-white',
            description ? 'mb-8' : null,
            topComponent ? 'mt-4' : null
          )}
        >
          {title}
        </h1>

        {description ? (
          <p
            className={twMerge(
              'text-xl font-light text-center max-w-[760px]',
              useHighContrast ? 'text-inherit' : 'text-white'
            )}
          >
            {description}
          </p>
        ) : null}
      </div>
    </section>
  );
}

export default PageTitle;
