import Head from 'next/head';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

import { useAccessibilitySettings } from '@/hooks/useAccessibilitySettings';

import Layout from '@/components/Layout';
import PageTitle from '@/components/sections/PageTitle';

function Custom404() {
  const { useHighContrast } = useAccessibilitySettings();

  return (
    <Layout>
      <Head>
        <title>404 - Page not found</title>
      </Head>

      <PageTitle title="404 - Page not found" />

      <section
        className={twMerge(
          'py-20',
          useHighContrast ? 'bg-contrast-yellow text-black' : 'bg-[#f3f3e0]'
        )}
      >
        <div className="container">
          <h2 className="text-center text-xl font-light">
            {`We're sorry, but we can't find the page that you requested.`}
          </h2>

          <div className="flex justify-center mt-6">
            <Link prefetch={false} href="/">
              <a
                className={twMerge(
                  'text-center',
                  useHighContrast ? 'link-contrast' : 'link-primary'
                )}
              >
                {`Click here to go back to the homepage`}
              </a>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Custom404;
